import Slide1 from "../Assets/wireframeSlide1.png"


const WireframeSlide1 = ({next}) => {
  return (
    <div>
        <div className="wireframe-img-container">
        <img className="wireframe-slider-img" src={Slide1} alt="" />
        </div>
        <div onClick={next} className="wireframe-next-btn">next</div>
    </div>
  )
}

export default WireframeSlide1