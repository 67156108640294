import Navbar from "./Navbar";

import minimalistBanner from "../Assets/minimalistBanner.png";
import minimalistHomeScreen from "../Assets/minimalistHomeScreen.png";
import minimalistDesktopScreen1 from "../Assets/minimalistDesktopScreen1.png";
import minimalistMobileHomeScreen from "../Assets/minimalistMobileHomeScreen.png";
import minimalistProductScreen from "../Assets/minimalistProductScreen.png";
import desktopProductScreen from "../Assets/desktopProductScreen.png";
import DesktopCartScreen from "../Assets/DesktopCartScreen.png";
import mobileCartScreen from "../Assets/mobileCartScreen.png";
import desktopCheckoutScreen from "../Assets/desktopCheckoutScreen.png";
import mobileCheckoutScreen from "../Assets/mobileCheckoutScreen.png";
import Footer from "./Footer";

const Minimalist = () => {
  return (
    <div>
      <Navbar />
      <div>
        <div className="minimalist-container">
          <div className="minimalist-landing">
            <div className="minimalist-img-container">
              <img
                className="minimalist-banner"
                src={minimalistBanner}
                alt=""
              />
            </div>
            <div className="minimalist-landing-text">
              <ul>
                <li>Branding</li>
                <li>UI Design</li>
                <li>Desktop and Mobile</li>
                <li>Creative Direction</li>
              </ul>
            </div>
          </div>
          <div className="minimalist-heading">
            <h1>
              minimalist <br />
              <span>ecommerce store UI concept for mobile and desktop </span>
            </h1>
          </div>
          <div className="minimalist-overview">
            <h3>Project overview</h3>
            <p>
              The task was to create an intuitive and user-friendly Desktop and
              Mobile Interface for an E-commerce store that specializes in
              selling lifestyle products that helps people easily create a
              minimalistic life. The design goal was to reflect the art of
              simplicity throughout the website, thereby offering a clean and
              straightforward shopping experience for customers.
            </p>
            <p>
              To achieve this, the design focused on minimalism, utilizing empty
              space in the layout to allow products to stand out. Clean, modern
              fonts were used for text to convey a sense of elegance and
              sophistication, while also ensuring legibility. Simple shapes were
              chosen for buttons, providing a streamlined and intuitive
              interface that is easy to navigate for users. The Desktop and
              Mobile Interface was created with a similar minimalist aesthetic,
              offering a cohesive shopping experience for customers, regardless
              of the device they are using. The design aimed to provide a sense
              of calm ensuring that customers can find what they are looking for
              quickly and easily.
            </p>
          </div>
          <div className="minimalist-home-screen">
            <h2>Home Screen mobile</h2>
            <hr />
            <div className="home-screen-content">
              <p>
                The mobile landing screen has a horizontal slider, providing an
                immersive experience of the store's offerings. A radial dark
                overlay on the image container ensures the slider buttons are
                visible. The easy navigation system includes a search icon at
                the bottom for seamless user experience.
              </p>
              <img src={minimalistHomeScreen} alt="" />
            </div>
          </div>
        </div>
        <div className="minimalist-container-2">
          <div className="minimalist-home-desktop">
            <h2>Home Desktop</h2>
            <hr />
            <img src={minimalistDesktopScreen1} alt="" />
            <div className="mobile-home-screen-p">
              <h3>Mobile Home Screen</h3>
              <p>
                The landing screen features editorial-style images that grab the
                user's attention and plenty of white space for a minimalist
                look. It includes call-to-action buttons for collections and
                product recommendations, and users can like products.
              </p>
            </div>
            <img src={minimalistMobileHomeScreen} alt="" />
            <div className="mobile-home-screen-p">
              <h3>Mobile Product Screen</h3>
              <p>
                Featuring a horizontal Slider and a Clear Call to Action with an
                Add to cart Button. I also add a Sticky and easy to access
                search button so the user can easily find a product they are
                looking for
              </p>
            </div>
            <img src={minimalistProductScreen} alt="" />
            <div className="mobile-home-screen-p">
              <h3>Desktop product Screen</h3>
              <p>I chose to use a radio button for colour selection</p>
              <p>
                The website design incorporates a convenient drop-down accordion
                feature that allows for easy nesting of product information,
                ensuring a clean and uncluttered appearance for the site.
              </p>
            </div>
            <img src={desktopProductScreen} alt="" />
            <div className="mobile-home-screen-p">
              <h3>Desktop cart screen</h3>
              <p>
                Showing order summery that is easy to read . User also has the
                option of removing an item from their cart.
              </p>
              <p>
                I added Product Suggestions on the Cart Screen to get user to
                add more items to their cart before checking out as well as a
                Subrcribe to email list call to action
              </p>
            </div>
            <img src={DesktopCartScreen} alt="" />
            <div className="mobile-home-screen-p">
              <h3>Mobile Cart Screen</h3>
              <p>
                The cart page prominently features a large call-to-action button
                for checkout, while also offering a "continue shopping" button
                to encourage users to explore more products on the store.
              </p>
              <p>
                I added an extra home button so user can easily navigate to the
                homepage should they want to keep browsing
              </p>
            </div>
          </div>
          <img
            className="minimalist-cart-screen"
            src={mobileCartScreen}
            alt=""
          />
          <div className="minimalist-home-desktop">
            <div className="mobile-home-screen-p">
              <h3>Desktop Checkout Screen</h3>
              <p>
                Here the goal was to create an easy and clear 3 step checkout
                process. User can review their order while checking out.
              </p>
            </div>
            <img src={desktopCheckoutScreen} alt="" />
            <div className="mobile-home-screen-p">
              <h3>Mobile Checkout Screen</h3>
              <p>
                For the mobile screen I chose to keep the logo fixed to top
                position to avoid distraction while user is filling out
                information
              </p>
              <p>
                User can review their order by taping on the view order button A
                cart overlay will slide In from the side showing their order
                summery
              </p>
            </div>
          </div>
          <img
            className="minimalist-cart-screen"
            src={mobileCheckoutScreen}
            alt=""
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Minimalist;
