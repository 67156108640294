import Navbar from "./Navbar";
import WireframeSlider from "./WireframeSlider";
import VideoPlayer from "./Prototype";

import coverImage from "../Assets/influenced-Landing.jpg";
import phoneImage from "../Assets/phoneImg1.png";
import sitemap from "../Assets/influencedSitemap.png";
import logo from "../Assets/influenced-logo.png";
import icon from "../Assets/influenced-icon.png";
import colorPalette from "../Assets/colorPalette.png";
import welcomeScreen from "../Assets/influencedWelcomeScreen.png";
import typography from "../Assets/typography.png";
import designScreens from "../Assets/designScreens.png";
import influencedHomeScreen from "../Assets/influencedHomeScreen.png";
import brandProfileScreen from "../Assets/brandProfileScreen.png";
import influencedHomeScreen2 from "../Assets/influencedHomeScreen2.png";
import profileScreen from "../Assets/profileScreen.png";
import portfolioScreen from "../Assets/portfolioScreen.png";
import portfolioScreen2 from "../Assets/portfolioScreen2.png";
import influencedLandingScreens from "../Assets/influencedLandingScreens.png";
import browsingScreen from "../Assets/browsingScreen.png";

// Mobile Images

import inlfluencedHomeScreenMobile from "../Assets/inlfluencedHomeScreenMobile.png";
import brandProfileMobile from "../Assets/brandProfileMobile.png";
import portfolioScreenMobile from "../Assets/portfolioScreenMobile.png";
import portfolioScreenMobile2 from "../Assets/portfolioScreenMobile2.png";
import Footer from "./Footer";

const Influenced = () => {
  return (
    <div className="outerContainer">
      <Navbar />
      <div className="influenced-landing-container">
        <div className="influenced-banner-img-container">
          <img className="influenced-cover-image" src={coverImage} alt="" />
        </div>
        <div className="dev-portfolio-landing-text">
          <ul>
            <li>Branding</li>
            <li>UX/UI Design</li>
            <li>Mobile APP design</li>
            <li>Creative Direction</li>
          </ul>
        </div>
      </div>
      <div className="influenced-heading">
        <h1>influenced</h1>
        <p>
          the app that connects brands with the right <br /> influencers to get
          real campaign results
        </p>
      </div>


      
      <div className="influenced-overview">
        <div className="influenced-left-grid">
          <h6>Influenced App</h6>
          <p>
            a networking platform that streamlines the process for brands to
            find and hire influencers, while also providing a professional
            platform for influencers to showcase their work, skills, and
            portfolio, expand their network, and get discovered
          </p>
          <img className="img-phone-1" src={phoneImage} alt="" />
        </div>
        <div className="influenced-right-grid">
          <h6>Project overview - UI/UX Zero to Mastery Bootcamp project</h6>
          <p>
            For my final project I wanted to design an app that would solve a
            business problem that I have come across as an owner of a Ecommerce
            store and Lifestyle brand that primarily relies on social media and
            influencer marketing to drive sales and online traffic. I followed
            the Design Thinking process to draft a problem statement and come up
            with a solution.
          </p>
          <h3>Empathise and Define the problem</h3>
          <h4>BRANDS</h4>
          <p>
            User-generated content and influencer marketing are crucial
            components of any brand's marketing strategy. To establish a more
            authentic connection with your target audience, it's essential to
            use both macro and micro-influencers. However, finding the right
            influencer who aligns with your brand values, product, budget,
            timeframe, and target market can be time-consuming and can result in
            biased decisions.
          </p>
          <p>
            Unfortunately, most influencers don't have a website where they
            showcase their portfolio of past campaigns, rates, engagement types,
            audience demographics, and reach statistics. This makes the process
            of finding the right influencer for your campaign incredibly
            frustrating. Therefore, it's necessary to find a reliable source
            that can provide you with the necessary information to make an
            informed decision.
          </p>
          <h4>INFLUENCERS</h4>
          <p>
            Influencers want to get hired by reputable brands but having a
            professional personal website that showcase their work can be
            expensive and is not accessible to everyone. Influencers also
            struggle to have a good working relationship with brands as there
            are no professional platform managing the hiring process and it
            often just relies on informal commitments that are often not follow
            through on. This causes frustration for influencers as they rely on
            the income they make with their work.
          </p>
          <h3>The Solution</h3>
          <h4>BRANDS</h4>
          <p>
            What if there was a platform or app that gathers information from
            various social media platforms on influencers, and provides brands
            with a profile page containing all the necessary information to make
            an informed decision on which influencer to work with for their
            marketing campaign. This platform would simplify the process of
            finding, connecting, and hiring the right influencer to drive sales
            and build brand awareness. Essentially, it would be a one-stop-shop
            for brands looking to partner with influencers for their campaigns.
          </p>
          <h4>INFLUENCERS</h4>
          <p>
            What if there was an app designed specifically for influencers to
            showcase their social media profiles, past campaigns, and other
            valuable information to potential brands? This app would allow
            influencers to set up a personal profile and portfolio, including
            metrics such as total reach, demographics, interests, and rates. By
            using the app, influencers would increase their chances of being
            discovered by reputable brands and would have a safer option for
            accepting work. The app would also streamline the hiring and payment
            process, making it easier for both influencers and brands to
            collaborate effectively.
          </p>
        </div>
      </div>
      <div className="influenced-design-section">
        <div className="design-deliverables-left">
          <h3>Design deliverables </h3>
          <ul>
            <li>Creative direction</li>
            <li>App Style Guide</li>
            <li>Logo and Icon design</li>
            <li>Wireframes</li>
            <li>Sitemap</li>
            <li>User flows</li>
            <li>Mobile App screen designs</li>
            <li>Components</li>
            <li>Prototype</li>
          </ul>
        </div>
        <div className="design-deliverables-right">
          <h3>Research, Discovery, Ideate and Design </h3>
          <p>
            During the initial phase of the project, I conducted extensive
            research on existing job portal apps to understand their
            functionality and UX design. This served as a starting point for
            developing the sitemap, user flows, and potential features for the
            app. I generated rough sketches while brainstorming initial ideas
            and prioritizing important task flows.
          </p>
          <p>
            To determine the look and feel of the app and identify the necessary
            screens, I examined various platforms such as Upwork, Fiverr, and
            Offer Zen for inspiration. Additionally, I gathered design ideas
            from visual discovery platforms like Pinterest and Dribble. By
            synthesizing my research findings, I created a solid foundation for
            the app's user interface design.
          </p>
          <h3>Key factors in design process </h3>
          <h6>Efficient filtering system:</h6>
          <p>
            The app should include a highly efficient filtering system that
            enables Brands to easily find the most suitable Influencers for
            their campaigns. This system should be designed with features that
            allow Brands to filter Influencers based on various criteria such as
            demographic information, expertise, social media following, and
            engagement rate. This will ensure that Brands are able to quickly
            and accurately identify the Influencers who are most likely to
            deliver the best results for their campaigns.
          </p>
          <h6>Influencer profile screen: </h6>
          <p>
            The app should feature a user-friendly and aesthetically pleasing
            profile screen for Influencers, which will serve as their
            personalized portfolio. This screen should be designed in a way that
            makes it easy for Influencers to showcase their skills, experience,
            and accomplishments to potential clients.
          </p>
        </div>
      </div>
      <div className="influence-sitemap-container">
        <h1>Sitemap</h1>
        <hr />
        <div className="sitemap-img-container">
          <img className="sitemap" src={sitemap} alt="" />
        </div>
      </div>
      <WireframeSlider />
      <div className="influenced-logo-section">
        <h1>Logo and Creative Direction</h1>
        <hr />
        <div className="influenced-logo-text-div">
          <p className="influenced-logo-p1">
            The goal was to achieve a professional aesthetic through a
            minimalist design, while also incorporating subtle retro elements to
            connect with the informal and relatable nature of a social platform
          </p>
          <p className="influenced-logo-p2">
            I drew inspiration from mid-century retro styles and minimal
            editorial layouts to create a cohesive aesthetic and creative
            direction.
          </p>
        </div>
        <div className="influenced-logo-img">
          <img className="logo-img-1" src={logo} alt="" />
          <img className="logo-img-2" src={icon} alt="" />
        </div>
        <h1>Colour Palette</h1>
        <img className="color-palette" src={colorPalette} alt="Color Palette" />
      </div>
      <div className="influenced-typography-section">
        <div className="typography-left">
          <p>
            To create a professional and calming look for the brand, a neutral
            colour palette was chosen, with a brighter light blue used as the
            main brand and accent colour. This specific shade of blue conveys
            trust, which is essential for the app to function as a reliable
            networking platform for brands and influencers. The light blue also
            adds a subtle retro vibe that connects to the social aspect of the
            platform. The neutral backdrop is perfect for showcasing influencer
            content, and the dark charcoal secondary colour is used primarily
            for text and calls-to-action to ensure readability and contrast.
          </p>
          <img
            className="influenced-welcome-screen-desktop"
            src={welcomeScreen}
            alt="Influenced Welcome Screen"
          />
        </div>
        <img
          className="influenced-typography-img"
          src={typography}
          alt="Typography"
        />
      </div>
      <img
        className="influenced-welcome-screen-mobile"
        src={welcomeScreen}
        alt="Influenced Welcome Screen"
      />
      <div className="influenced-design-screens">
        <div className="design-background-div"></div>
        <div className="design-screen-left">
          <h1>Final Screen Designs</h1>
          <h3>Log in and sign up screens.</h3>
          <p>
            Because the app has two objectives , the user have to check the
            required check box asking if they will be using the app as a Brand
            or Influencer when they are creating an account.
          </p>
          <p>
            This field is required so if the user doesn't check this box an
            error message will pop up
          </p>
          <p>
            On the log in screen the User has the option to log in with social
            accounts.
          </p>
        </div>
        <div className="design-screen-right">
          <img src={designScreens} alt="Influenced Design Screens" />
        </div>
        <div className="home-screen-left">
          <h3>Home Screen</h3>
          <p>
            Featuring newest influencers, most viewed campaigns and popular
            influencers, navigation bar and ability to search
          </p>
          <img src={influencedHomeScreen} alt="Home Screen" />
          <h3>Brand Profile screen</h3>
          <p>
            The brand profile allows the user to link their company website with
            a button click.
          </p>
          <p>
            It also features a accordion drop down. When they add an Influencer
            their Profile card is saved underneath the Brands network list. This
            is also where the brand can add their company values so an
            influencer can easily see what the company is all about.
          </p>
          <p>
            It also has an navigation bar that can open and close. The active
            state of the screen is shown in the navigation bar when the icon is
            blue and underlined.
          </p>
          <img src={brandProfileScreen} alt="Home Screen" />
          <img className="profile-screen-left" src={profileScreen} alt="" />
          <img src={portfolioScreen} alt="Portfolio Screen" />
        </div>

        <div className="home-screen-right">
          <div className="influenced-spacer"></div>
          <h3>Home Screen</h3>
          <p>
            To view influencers profile info and portfolio you have to be logged
            in as a member. When a user taps on an Influencer they want to view
            the are prompted with a notification to log in or create an account
          </p>
          <img src={influencedHomeScreen2} alt="Home Screen" />
          <p>
            It has a navigation bar that can open and close. The active state of
            the screen is shown in the navigation bar when the icon is blue and
            underlined.
          </p>
          <h3>Influencer Profile screen</h3>
          <p>
            The Influencer Profile screen is a minimalist and professional
            platform for influencers to showcase their work and provide
            information about their social media following, engagement, reviews,
            and previous campaigns. The information is organized in an
            accordion-style drop-down, which saves space and keeps the interface
            clean. A hidden navigation bar is also available for influencers to
            edit their profile, track their campaigns, and manage their
            accounts. The result is a user-friendly and intuitive interface that
            helps brands find suitable influencers to collaborate with.
          </p>
          <h3>Added to network Notification Message</h3>
          <p>
            When the hire button is clicked added an Influencer to their network
            a notification pops up and the button changes to state that that a
            request has been sent
          </p>
          <img src={portfolioScreen2} alt="Influencer Portfolio Screen" />
          <p className="p-heading-alternative">
            When a Brand has successfully added an Influencer to their network a
            notification pops up and the add to network button changes to state
            that they are now connected
          </p>
        </div>

        {/* Home Screen Mobile section */}

        <div className="home-screen-left-mobile">
          <h3>Home screen</h3>
          <p>
            Home screen featuring newest influencers, most viewed campaigns and
            popular influencers, navigation bar and ability to search to view
            influencers profile info and portfolio you have to be logged in as a
            member. When a user taps on an Influencer they want to view the are
            prompted with a notification to log in or create an account It has a
            navigation bar that can open and close.
          </p>
          <p>
            The active state of the screen is shown in the navigation bar when
            the icon is blue and underlined.
          </p>
          <img src={inlfluencedHomeScreenMobile} alt="" />
          <h3>Brand Profile screen</h3>
          <p>
            The brand profile allows the user to link their company website with
            a button click.
          </p>
          <p>
            It also features a accordion drop down. When they add an Influencer
            their Profile card is saved underneath the Brands network list. This
            is also where the brand can add their company values so an
            influencer can easily see what the company is all about.
          </p>
          <p>
            It also has an navigation bar that can open and close. The active
            state of the screen is shown in the navigation bar when the icon is
            blue and underlined.
          </p>
          <img className="brandProfileMobile" src={brandProfileMobile} alt="" />
          <h3>Influencer Profile screen</h3>
          <p>
            The Influencer Profile screen is a minimalist and professional
            platform for influencers to showcase their work and provide
            information about their social media following, engagement, reviews,
            and previous campaigns. The information is organized in an
            accordion-style drop-down, which saves space and keeps the interface
            clean. A hidden navigation bar is also available for influencers to
            edit their profile, track their campaigns, and manage their
            accounts. The result is a user-friendly and intuitive interface that
            helps brands find suitable influencers to collaborate with.
          </p>
          <h3>Added to network Notification Message</h3>
          <p>
            When the hire button is clicked added an Influencer to their network
            a notification pops up and the button changes to state that that a
            request has been sent
          </p>
          <img
            className="brandProfileMobile"
            src={portfolioScreenMobile}
            alt=""
          />
          <p>
            When a Brand has successfully added an Influencer to their network a
            notification pops up and the add to network button changes to state
            that they are now connected
          </p>
          <img className="brandProfileMobile" src={portfolioScreenMobile2} alt="" />
        </div>

        <div className="landing-screens">
          <div className="landing-page-text1">
            <h3>Landing screens when logged in as a Brand</h3>
            <p>
              When a Brand has successfully logged in the app will show a splash
              screen and then the brand will be prompted to ether create
              campaign or go straight to browsing for influencers.
            </p>
          </div>
          <img
            className="landing-screens-img"
            src={influencedLandingScreens}
            alt=""
          />
          <div className="landing-page-text2">
            <h3>Easy Filter/ form screens to show relevant results</h3>
            <p>
              I nested the information into an accordion so it is easy to use
              and fill out. To keep the form looking as minimalist as possible
              and quick to fill out I made sure I used the best suited input
              controls for each element for better UX. I used input controls
              such as radio buttons, dropdown lists, sliders and easy to select
              category pills
            </p>
          </div>
        </div>
        <div className="landing-background-div"></div>
      </div>
      <div className="browsing-screens">
        <img src={browsingScreen} alt="Influenced browsing screens" />
        <div className="browsing-text">
          <h3>Influencer Browsing screen</h3>
          <p>
            The main browsing screen has a modern and minimal layout that
            displays the most important information about influencers. After
            researching user preferences, a horizontal slider was chosen for
            browsing. From this screen, brands have the option to view an
            influencer's profile or add them to their network for later viewing.
            Additionally, social media accounts can be accessed through linked
            icons. To ensure readability, a dark linear gradient fill was added
            to the main influencer card, preventing any impact on readability
            caused by the colour of the influencer's photo, whether it's light
            or dark.
          </p>
        </div>
      </div>
      <div className="influenced-video-container">
        <VideoPlayer />
      </div>
      <Footer />
    </div>
  );
};

export default Influenced;
