import Slide3 from "../Assets/wireframeSlide3.png"


const WireframeSlide3 = ({prev}) => {
  return (
    <div>
        <div className="wireframe-img-container">
        <img className="wireframe-slider-img" src={Slide3} alt="" />
        </div>
        <div onClick={prev} className="wireframe-prev-btn">prev</div>
    </div>
  )
}

export default WireframeSlide3