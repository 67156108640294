import emailjs from "emailjs-com";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import AOS from "aos";
import "aos/dist/aos.css";

const Footer = (props) => {
  const [message, setMessage] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "Africa/Johannesburg",
      };
      const formattedTime = now.toLocaleTimeString("en-ZA", options);
      setCurrentTime(formattedTime);
    };

    updateTime();

    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    AOS.init({});
  });

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Replace these with your own EmailJS credentials
    const serviceId = "service_1pki16m";
    const templateId = "template_7jjqkg3";
    // const userId = 'YOUR_USER_ID';
    const userId = "5fd9By4QqTCf3ronL";

    // Prepare the email parameters
    const templateParams = {
      message,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log("Email sent successfully:", response);
        setIsEmailSent(true);
        // Handle success or display a confirmation message to the user
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        // Handle the error accordingly
      });
  };

  return (
    <div>
      <hr />
      <div className="footer-section">
        <div className="left-footer-section">
          <h3>SITEMAP</h3>
          <ul>
            <HashLink smooth to="/landing#top">
              <div className="hidden">
                <li data-aos="slide-up" data-aos-delay="100">HOME</li>
              </div>
            </HashLink>
            <Link to="/about">
              <div className="hidden">
                <li data-aos="slide-up" data-aos-delay="200">ABOUT</li>
              </div>
            </Link>
            {/* <HashLink smooth to="/landing#skills">
              <div className="hidden">
                <li data-aos="slide-up" data-aos-delay="300">SKILLS</li>
              </div>
            </HashLink> */}
            <HashLink smooth to="/landing#portfolio">
              <div className="hidden">
                <li data-aos="slide-up" data-aos-delay="400">PORTFOLIO</li>
              </div>
            </HashLink>
          </ul>
          <div className="hidden">
          <div onClick={props.onClick} className="footer-cv" data-aos="slide-up" data-aos-delay="500">
            <p>Download cv</p>
          </div>
          </div>
          <p className="footnote">developed by <a className="ruan-link" href="https://ruanradynportfolio.netlify.app/">Ruan Radyn</a></p>
        </div>
        <div className="center-footer-section">
          <h3>FIND ME ON SOCIALS</h3>
          <ul>
          <div className="hidden">
            <li data-aos="slide-up" data-aos-delay="600">
              <a
                href="https://www.instagram.com/you_me_andthemoon/"
                target="blank"
              >
                INSTAGRAM
              </a>
            </li>
            </div>
            <div className="hidden">
            <li data-aos="slide-up" data-aos-delay="700">
              <a
                href="https://www.linkedin.com/in/inge-radyn-245782255/"
                target="blank"
              >
                LINKEDIN
              </a>
            </li>
            </div>
            <div className="hidden">
            <li data-aos="slide-up" data-aos-delay="800"> 
              <a href="https://www.behance.net/ingeradyn/" target="blank">
                BEHANCE
              </a>
            </li>
            </div>
            <div className="hidden">
            <li data-aos="slide-up" data-aos-delay="900">
              <a href="https://dribbble.com/IngeRadyn" target="blank">
                DRIBBLE
              </a>
            </li>
            </div>
          </ul>
          <p>
            BASED IN CAPE TOWN, WORKING WORLDWIDE <br />
            <span>LOCAL TIME: {currentTime}</span>
          </p>
        </div>
        <div className="right-footer-section">
          <h3>LEAVE YOUR EMAIL AND SAY A QUICK HI </h3>
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <input type="text" value={message} onChange={handleInputChange} />
              {isEmailSent ? (
                <p className="sent-message">THANKS, I'LL BE IN TOUCH</p>
              ) : (
                <button type="submit">SEND</button>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="footer-section-mobile">
        <div className="right-footer-section">
          <h3>LEAVE YOUR EMAIL AND SAY A QUICK HI </h3>
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <input type="text" value={message} onChange={handleInputChange} />
              {isEmailSent ? (
                <p className="sent-message">THANKS, I'LL BE IN TOUCH</p>
              ) : (
                <button type="submit">SEND</button>
              )}
            </form>
          </div>
        </div>
        {/* Mobile footer */}
        <div className="mobile-footer-nav">
          <div className="left-footer-section">
            <h3>SITEMAP</h3>
            <ul>
              <HashLink smooth to="/landing#top">
                <div className="hidden">
                  <li data-aos="slide-up" data-aos-delay="100">HOME</li>
                </div>
              </HashLink>
              <Link to="/about">
              <div className="hidden">
                <li data-aos="slide-up" data-aos-delay="200">ABOUT</li>
                </div>
              </Link>
              {/* <HashLink smooth to="/landing#skills">
              <div className="hidden">
                <li data-aos="slide-up" data-aos-delay="300">SKILLS</li>
                </div>
              </HashLink> */}
              <HashLink smooth to="/landing#portfolio">
              <div className="hidden">
                <li data-aos="slide-up" data-aos-delay="400">PORTFOLIO</li>
                </div>
              </HashLink>
            </ul>
            <div className="hidden">
          <div onClick={props.onClick} className="footer-cv" data-aos="slide-up" data-aos-delay="500">
            <p>Download cv</p>
          </div>
          </div>
            <p className="footnote">developed by <a className="ruan-link" href="https://ruanradynportfolio.netlify.app/">Ruan Radyn</a></p>
          </div>
          <div className="center-footer-section">
            <h3>FIND ME ON SOCIALS</h3>
            <ul>
            <div className="hidden">
              <li data-aos="slide-up" data-aos-delay="500">
                <a
                  href="https://www.instagram.com/you_me_andthemoon/"
                  target="blank"
                >
                  INSTAGRAM
                </a>
              </li>
              </div>
              <div className="hidden">
              <li data-aos="slide-up" data-aos-delay="600">
                <a
                  href="https://www.linkedin.com/in/inge-radyn-245782255/"
                  target="blank"
                >
                  LINKEDIN
                </a>
              </li>
              </div>
              <div className="hidden">
              <li data-aos="slide-up" data-aos-delay="700">
                <a href="https://www.behance.net/ingeradyn/" target="blank">
                  BEHANCE
                </a>
              </li>
              </div>
              <div className="hidden">
              <li data-aos="slide-up" data-aos-delay="800">
                <a href="https://dribbble.com/IngeRadyn" target="blank">
                  DRIBBLE
                </a>
              </li>
              </div>
            </ul>
            <p>
              BASED IN CAPE TOWN, WORKING WORLDWIDE <br />
              <span>LOCAL TIME: {currentTime}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
