import profileImage from "../Assets/profileImage.png";
import readMoreBtn from "../Assets//readMoreBtn.svg";
import buttonOverlay from "../Assets/btnOverlay.svg";

import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Slide1() {

  useEffect(() => {
    AOS.init({
    });
  });

  return (
    <div>
      <div className="slide1-container" id="about">
        <div className="about-text">
          <h1 className="about-title" data-aos="fade-up">about</h1>
          <div className="about-text-container">
            <div className="hidden">
            <p className="about-text1" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" data-aos-duration="800">
              I gravitate towards minimalism in my design approach where every
              element is carefully considered and human centred
            </p>
            </div>
            <div className="hidden">
            <p className="about-text2" data-aos="fade-up" data-aos-offset="200" data-aos-duration="1000" >
              I use my intuitive skills as a visual artist and <br /> eye for
              striking aesthetics combined with my <br /> professional
              experience in graphic design to <br /> create a memorable user
              experience that <br /> is enjoyable and absolutely makes sense.
            </p>
            </div>
          </div>
        </div>
        <div className="about-image-div">
          <img className="about-image" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom" data-aos-duration="1200"  src={profileImage} alt="" />
          <Link to="/about">
            <div className="next-btn-container">
              <img className="next-btn" src={readMoreBtn} alt="" />
              <img className="overlay-next-btn" src={buttonOverlay} alt="" />
            </div>
          </Link>
        </div>
      </div>
      <div className="about-mobile-slider" id="mobileAbout">
        <div className="about-text">
          <h1 className="about-title">about</h1>
          <div className="about-text-container">
          <div className="hidden">
            <p className="about-text1" data-aos="fade-up" data-aos-offset="200" data-aos-duration="800">
              I gravitate towards minimalism in my design approach where every
              element is carefully considered and human centred
            </p>
            </div>
            <div className="hidden">
            <p className="about-text2" data-aos="fade-up" data-aos-offset="200" data-aos-duration="1000">
              I use my intuitive skills as a visual artist and eye for striking
              aesthetics combined with my professional experience in graphic
              design to create a memorable user experience that is enjoyable
              and absolutely makes sense.
            </p>
            </div>
          </div>
        </div>
        <div className="about-image-div">
          <img className="about-image" data-aos="fade-up" src={profileImage} alt="" />
          <Link to="/about">
            <div className="next-btn-container">
              <img className="next-btn" src={readMoreBtn} alt="" />
              <img className="overlay-next-btn" src={buttonOverlay} alt="" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
