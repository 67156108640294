import aboutImage2 from "../Assets/aboutImage3.png";
import aboutFooterImg from "../Assets/aboutFooterImg.png";
import prevButton from "../Assets/downloadCV.png";
import buttonOverlay from "../Assets/btnOverlay.png";
import profileImage from "../Assets/profileImage.png";
import Navbar from "./Navbar";
import { HashLink } from "react-router-hash-link";
import Footer from "./Footer";

export default function About() {
  const onClick = () => {
    fetch("Inge_Radyn_CV.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);

        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Inge_Radyn_CV.pdf";
        alink.click();
      });
    });
  };

  return (
    <div>
      <Navbar />
      <div className="slide1-container">
      <h1 className="about-title2">something about me</h1>
        <div className="about-text">
          <div className="about-text-container">
            <p className="about-text1">
              I gravitate towards minimalism in my design approach where every element is carefully considered and human centred
            </p>
            <p className="about-text2">
              I use my intuitive skills as a visual artist and eye for striking aesthetics combined with my professional experience in graphic design
              to create a memorable user experience that is enjoyable and absolutely makes sense.
            </p>
          </div>
        </div>
        <div className="about-image-div">
          <img className="about-image" src={profileImage} alt="" />
          <div className="arrow-container">
            <HashLink smooth to="#moreAbout">
              <svg width="20" height="45" viewBox="0 0 16 41" fill="none" xmlns="http://www.w3.org/2000/svg" className="about-down-arrow-desktop">
                <path
                  d="M7.29289 40.7071C7.68342 41.0976 8.31658 41.0976 8.7071 40.7071L15.0711 34.3431C15.4616 33.9526 15.4616 33.3195 15.0711 32.9289C14.6805 32.5384 14.0474 32.5384 13.6569 32.9289L8 38.5858L2.34314 32.9289C1.95262 32.5384 1.31946 32.5384 0.928931 32.9289C0.538406 33.3195 0.538406 33.9526 0.928931 34.3431L7.29289 40.7071ZM7 -4.37114e-08L7 40L9 40L9 4.37114e-08L7 -4.37114e-08Z"
                  fill="#6CA68A"
                />
              </svg>
            </HashLink>
          </div>
        </div>
      </div>
      <div className="about-mobile-slider">
        <div className="about-text">
          <h1 className="about-title2">about</h1>
          <div className="about-text-container">
            <p className="about-text1">
              I gravitate towards minimalism in my design approach where every element is carefully considered and human centred
            </p>
            <p className="about-text2">
              I use my intuitive skills as a visual artist and eye for striking aesthetics combined with my professional experience in graphic design
              to create a memorable user experience that is enjoyable and absolutely makes sense.
            </p>
          </div>
        </div>
        <div className="about-image-div">
          <img className="about-image" src={profileImage} alt="" />
        </div>
        <div className="arrow-container">
          <HashLink smooth to="#mobileAbout">
            <svg width="16" height="41" viewBox="0 0 16 41" fill="none" xmlns="http://www.w3.org/2000/svg" className="about-down-arrow">
              <path
                d="M7.29289 40.7071C7.68342 41.0976 8.31658 41.0976 8.7071 40.7071L15.0711 34.3431C15.4616 33.9526 15.4616 33.3195 15.0711 32.9289C14.6805 32.5384 14.0474 32.5384 13.6569 32.9289L8 38.5858L2.34314 32.9289C1.95262 32.5384 1.31946 32.5384 0.928931 32.9289C0.538406 33.3195 0.538406 33.9526 0.928931 34.3431L7.29289 40.7071ZM7 -4.37114e-08L7 40L9 40L9 4.37114e-08L7 -4.37114e-08Z"
                fill="#6CA68A"
              />
            </svg>
          </HashLink>
        </div>
      </div>
      <div className="slide2-container" id="moreAbout">
        <div className="img-container-slide2">
          <div className="about-heading">
            <p>
              not <span>(just)</span> <br /> another ui <br /> designer
            </p>
          </div>
          <img className="about-img-2" src={aboutImage2} alt="" />
        </div>
        <div className="text-container-slide2">
          <div className="block1">
            <p>
              As a curious creative, my love and training in visual arts began at a very young age. This has taken me on a journey across various
              creative industries, including illustration, graphic design, visual communication, fashion design, creative direction, social media
              marketing, and printing.
            </p>
            <h3>So Why UI Design?</h3>
            <p>
              A few years ago, I embarked on an entrepreneurial journey into eCommerce by launching Willow and Roo, a sustainable kids brand.
              Designing the online store sparked my interest for UX/UI design, inspiring me to enroll in an online course with Zero to Mastery Academy
              to deepen my skills.
            </p>
            <p>
              Through the design and launch of Willow and Roo, I gained an in-depth understanding of the importance of customer and user experience.
            </p>
          </div>
          <div className="block2">
            <p>
              Through my diverse background I have acquired a versatile skill set that allows me to bring a fresh and innovative perspective to the
              field of UI Design. My love for visual arts continue to inspire me, and creating captivating and beautiful experiences for clients and
              audiences is what really gets me excited in the morning. I now get to design products that engage with people on a personal level. I see
              it as a purposeful form of design that can have a positive impact on the way we go about our day-to-day life.
            </p>
            <p>
              I have a serious entrepreneurial spirit, always trying to think of creative ways to solve problems and turn it into business ideas. I am
              a quick learner and enjoy broadening my skillset especially when my “hyper-focus mode" kicks in. Keep scrolling for a tiny glimpse into
              my personal world.
            </p>
            <div onClick={onClick} className="prev-btn-container">
              <img className="overlay-prev-btn" src={buttonOverlay} alt="" />
              <img className="prev-btn" src={prevButton} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="about-mobile-slider2" id="mobileAbout">
        <div className="about-heading">
          <p>
            not <span>(just)</span> <br /> another ui <br /> designer
          </p>
        </div>
        <div className="img-container-slide2">
          <img className="about-img-2" src={aboutImage2} alt="" />
        </div>
        <div className="text-container-slide2">
          <div className="block1">
            <p>
              As a curious creative, my love and training in visual arts began at a very young age. This has taken me on a journey across various
              creative industries, including illustration, graphic design, visual communication, fashion design, creative direction, social media
              marketing, and printing.
            </p>
            <h1>So Why UI Design?</h1>
            <p>
              A few years ago, I embarked on an entrepreneurial journey into eCommerce by launching Willow and Roo, a sustainable kids brand.
              Designing the online store sparked my interest for UX/UI design, inspiring me to enroll in an online course with Zero to Mastery Academy
              to deepen my skills.
            </p>
            <p>
              Through the design and launch of Willow and Roo, I gained an in-depth understanding of the importance of customer and user experience.
            </p>
          </div>
          <div className="block2">
            <p>
              Through my diverse background I have acquired a versatile skill set that allows me to bring a fresh and innovative perspective to the
              field of UI Design. My love for visual arts continue to inspire me, and creating captivating and beautiful experiences for clients and
              audiences is what really gets me excited in the morning. I now get to design products that engage with people on a personal level. I see
              it as a purposeful form of design that can have a positive impact on the way we go about our day-to-day life.
            </p>
            <p>
              I have a serious entrepreneurial spirit, always trying to think of creative ways to solve problems and turn it into business ideas. I am
              a quick learner and enjoy broadening my skillset especially when my “hyper-focus mode" kicks in. Keep scrolling for a tiny glimpse into
              my personal world.
            </p>
            <div onClick={onClick} className="prev-btn-container">
              <img className="overlay-prev-btn" src={buttonOverlay} alt="" />
              <img className="prev-btn" src={prevButton} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="about-footer-section">
        <h2>
          when I am <br /> not designing
        </h2>
        <h2 className="about-footer-mobile">when I am not designing</h2>
        <img src={aboutFooterImg} className="aboutFooterImg" alt="" />
        <p>
          I really love taking it slow and spending time with my husband and our golden retriever, Skylar. Living near the ocean means we get to start
          our day with a refreshing morning walk in the salty sea air. I'm a huge fan of traveling and I find that being surrounded by nature is where
          I feel the most inspired. When it comes to coffee, for me nothing really beats a flat white. This is me in a tiny nutshell. Keen to work
          together? Let’s connect.
        </p>
        <a href="mailto:hello.ingeradyn@gmail.com">hello.ingeradyn@gmail.com</a>
      </div>
      <Footer />
    </div>
  );
}
