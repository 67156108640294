import { useState } from "react";
import WireframeSlide1 from "./WireframeSlide1";
import WireframeSlide2 from "./WireframeSlide2";
import WireframeSlide3 from "./WireframeSlide3";

export default function WireframeSlider() {
  const [currentIndex, setCurrentIndex] = useState(1);

  const handleNext = () => {
    setCurrentIndex(currentIndex === 1 ? 2 : currentIndex === 2 ? 3 : 1);
  };

  const handlePrev = () => {
    setCurrentIndex(currentIndex === 3 ? 2 : currentIndex === 2 ? 1 : 3);
  };

  return (
    <div className="wireframe-slide-container">
      <h1>Wireframes and User flows</h1>
      <hr />
      <div className="wireframe-slider">
      {currentIndex === 1 ? (
        <WireframeSlide1 next={handleNext} />
      ) : currentIndex === 2 ? (
        <WireframeSlide2 prev={handlePrev} next={handleNext} />
      ) : (
        <WireframeSlide3 prev={handlePrev} />
      )}
      </div>
    </div>
  );
}
