import { useEffect } from "react";
import emailButton from "../Assets/emailButton.svg";
import overlay from "../Assets/emailButtonOverlay.svg";
import AOS from "aos";
import "aos/dist/aos.css";

function Contact() {

  useEffect(() => {
    AOS.init({
    });
  });

  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };


  return (
    <div className="contact-container" id="contact">
      <hr />
      <div className="contact-block1">
      <p className="get-in-touch">GET IN TOUCH</p>
      </div>
      <div className="contact-heading">
        <h1 data-aos="slide-up" data-aos-duration="1000">
          have a project in mind? <br /> <span >I’d love to hear about it</span>
        </h1>
        <Mailto
                  email="hello.ingeradyn@gmail.com"
                  subject="from portfolio"
                  body="Hey Inge"
                >
                  <div className="email-btn-container">
          <img className="email-btn" src={emailButton} alt="email me button" />
          <img className="email-overlay" src={overlay} alt="" />
        </div>
                </Mailto>
        
      </div>
      <div class="infinite-text-loop-container">
        <div class="infinite-text-loop">
          <span class="text">
            designed and developed with multiple cups of caffeine
            and creativity
          </span>
        </div>
      </div>
    </div>
  );
}

export default Contact;
