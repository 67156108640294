import button1 from "../Assets/splashButton1.svg";
import button2 from "../Assets/splashButton2.svg";

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const SplashScreen = () => {

  const navigate = useNavigate();

  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);


  useEffect(() => {
    // Use the useEffect hook to trigger the fade-in animation after the component mounts
    setFadeIn(true);
  }, []);

  const handleButtonClick = () => {
    setTimeout(() => {
      navigate('/landing'); 
    }, 2000);

    setFadeOut(true);
  };
  

  return (
    <div className="splash-screen">
      <div className={`welcome-text-container ${fadeIn ? "fade-in" : ""} ${fadeOut ? "fade-out" : ""}`}>

        <p>
          hey, <span>(insert pause)</span>
        </p>
        <p>
          it's <span>almost sunday</span> and you
        </p>
        <p>can call me inge</p>
      </div>
      <div className={`welcome-mobile-text-container ${fadeIn ? "fade-in" : ""} ${fadeOut ? "fade-out" : ""}`}>

        <p>
          hey, <span>(insert <br /> pause)</span>
        </p>
        <p>
          it's <span>almost <br /> sunday</span> and <br /> you can call
        </p>
        <p> me inge</p>
      </div>

      <div  className={`splash-btn-container ${fadeIn ? "fade-in" : ""} ${fadeOut ? "fade-out" : ""}`}>
        <Link onClick={handleButtonClick}>
          <img
            className="splash-btn-1"
            src={button1}
            alt="take a tour button"
          />
          <img
            className="splash-btn-2"
            src={button2}
            alt="take a tour button"
          />
        </Link>
      </div>
    </div>
  );
};

export default SplashScreen;
