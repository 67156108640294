import { Link } from "react-router-dom";
import logo from "../Assets/logo.svg";
// import { useEffect, useState } from "react";
import MobileMenu from "./MobileMenu";
// import MainMenu from "./MainMenu";

function Navbar() {
  // const [isMobileMenu, setIsMobileMenu] = useState(false)

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobileMenu(window.innerWidth <= 800)
  //   }

  //   window.addEventListener('resize', handleResize);
  //   handleResize()

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  return (
    <div className="navContainer">
      <div className="logo">
        <Link to="/landing">
          <img className="main-logo" src={logo} alt="" />
        </Link>
      </div>

      <MobileMenu />
    </div>
  );
}

export default Navbar;
