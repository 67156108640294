import { Link } from "react-router-dom";

import Navbar from "./Navbar";

import devPortfolio from "../Assets/devPortfolio.jpg";
import colourPalette from "../Assets/colourPalette.png";
import devTypography from "../Assets/devTypography.png";
import devResponsiveDesign from "../Assets/devResponsiveDesign.png";
import devResponsiveDesign2 from "../Assets/devResponsiveDesign2.png";
import devAbout from "../Assets/devAbout.png";
import devRecentWork from "../Assets/devRecentWork.png";
import Footer from "./Footer";

const DevPortfolio = () => {
  return (
    <div className="dev-portfolio-container">
      <Navbar />
      <div>
        <div className="dev-portfolio-landing">
          <div className="dev-portfolio-img-container">
            <img className="dev-portfolio-banner" src={devPortfolio} alt="" />
          </div>
          <div className="dev-portfolio-landing-text">
            <ul>
              <li>UI Design</li>
              <li>UX Design</li>
              <li>Desktop and Mobile Design</li>
              <li>Creative Direction</li>
              <li>Personal branding</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="dev-portfolio-heading">
        <h1>
          frontend developer portfolio <br />
          <span>redesign for mobile and desktop</span>
        </h1>
      </div>
      <div className="dev-portfolio-heading-text">
        <div>
          <h4>project overview- portfolio redesign </h4>
          <p>
            Redesign of a Front End Developers Portfolio with the main goal to
            make it responsive
          </p>
        </div>
        <div className="dev-portfolio-heading-text2">
          <p>
            During my recent work experience, I collaborated with a Junior Front
            End Developer who needed assistance in enhancing his Portfolio
            website by engaging a Ui Designer. The main objective was to revamp
            the website's appearance and create a design that would be both
            responsive and mobile-friendly. This was a critical task since the
            developer's prior portfolio website lacked these features, and there
            was a high likelihood that recruiters would view his portfolio on a
            mobile device. As a result, I made responsive development a top
            priority throughout the redesign process.
          </p>
        </div>
      </div>
      <div className="dark-background">
        <div className="dev-portfolio-process">
          <h1>My process</h1>
          <div>
            <div className="dev-portfolio-process-text1">
              <div>
                <h3>research and discovery</h3>
                <p>
                  I did a complete review of his current website and determined
                  what elements I could use to work from and what areas needed
                  improvement. I spent some time looking at other front end
                  developer portfolios and websites as well and some current
                  design trends to determine key aspects of a developer
                  portfolio and to find creative but efficient ways to display
                  skills and relevant information
                </p>
              </div>
              <div>
                <h3>ideate and design</h3>
                <p>
                  I worked on layout, button designs, typography hierarchy,
                  colour palette suggestions as well as added some personal
                  branding/logo elements. Overall the goal was to create a
                  responsive design that ensures a seamless flow and easy
                  navigation. I made some suggestions of adding CTA buttons and
                  suggested some micro interactions to enhance the viewers
                  experience and add a little personality to create a stand-out
                  website that will be noticed by recruiters
                </p>
              </div>
            </div>
            <div className="dev-portfolio-process-text2">
              <div>
                <h3>creative direction</h3>
                <p>
                  I determined a creative direction and look and feel that would
                  add a bit of personality to his website before starting to
                  work on the basic layout
                </p>
              </div>
              <div>
                <h3>handover and implement</h3>
                <p>
                  an ongoing collaborative process with Ruan communicating small
                  changes etc. While the site has gone live it is not 100%
                  finished yet but you can have look at the live site.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="dev-design-guide">
          <h1>Design Guide</h1>
          <div className="design-guide-img-container">
            <img src={colourPalette} alt="" />
            <img src={devTypography} alt="" />
          </div>
        </div>
      </div>
      <div className="dev-responsive-design">
        <div className="dev-responsive-design-img-container">
          <img src={devResponsiveDesign} alt="" />
          <img src={devResponsiveDesign2} alt="" />
        </div>
        <div className="dev-responsive-design-text">
          <div>
            <h3>Creative Direction</h3>
            <p>
              I used a 12 column Grid system and kept a clean minimal layout .
              All the imagery was changed to black and white to fit with the
              look and feel and keep things cohesive. I kept the colours neutral
              and decided to include a dark charcoal as dark colours are often
              associated with developers as they usually work in dark mode.
              Orange was chosen as an accent colour for CTA buttons and some
              elements to add some personality and a trending pop colour{" "}
            </p>
          </div>
          <div>
            <h3>Buttons</h3>
            <p>
              I suggested a CTA button here after viewer had seen some of his
              projects. I used pill shaped buttons to create a modern but space
              like feel
            </p>
            <h3>Nesting information</h3>
            <p>
              i used a concertina drop down to avoid cognitive overload and keep
              the minimal look of the design
            </p>
            <Link to="https://ruanradynportfolio.netlify.app/">
              <h4>VIEW LIVE SITE</h4>
            </Link>
          </div>
        </div>
      </div>
      <div className="dev-about-img">
        <img src={devAbout} alt="" />
      </div>
      <div className="dev-recent-work">
        <h1>Responsive  Screens</h1>
        <img src={devRecentWork} alt="" />
      </div>
      <Footer />
    </div>
  );
};

export default DevPortfolio;
