import './App.css';
import SplashScreen from './components/SplashScreen';
import Landing from './components/Landing';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Influenced from './components/Influenced';
import ScrollToTop from './components/ScrollToTop';
import DevPortfolio from './components/DevPortfolio';
import Minimalist from './components/Minimalist';
import About from './components/About';


function App() {

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" Component={SplashScreen} />
        <Route path="/landing" Component={Landing} />
        <Route path="/influenced" Component={Influenced} />
        <Route path="/devPortfolio" Component={DevPortfolio} />
        <Route path="/minimalist" Component={Minimalist} />
        <Route path="/about" Component={About} />
      </Routes>
    </Router>
  );
}

export default App;
