import influenced from "../Assets/influenced.png";
import minimaliste from "../Assets/minimaliste.png";
import devPortfolio from "../Assets/devPortfolio.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Portfolio = () => {

  useEffect(() => {
    AOS.init({
    });
  });

  return (
    <div className="portfolio-container" id="portfolio">
      <h1 className="portfolio-heading">personal projects</h1>
      <div className="portfolio-cards-container">
        <div className="influenced" data-aos="fade-up" data-aos-duration="800">
          <p className="card-number">1/3</p>
          <img src={influenced} alt="" />
          <h1>influenced</h1>
          <p className="card-par">
            An app that helps brands easily find and hire influencers for
            marketing campaigns
          </p>
          <Link to="/influenced">
            <button className="view-case-study-btn">view case study</button>
          </Link>
        </div>
        <div className="minimaliste" data-aos="fade-up" data-aos-duration="600">
          <p className="card-number">2/3</p>
          <img src={minimaliste} alt="" />
          <h1>minimalist</h1>
          <p className="card-par">
          A UI concept ecommerce store for mobile and desktop with a focus on easy navigation and clean design
          </p>
          <Link to="/minimalist">
            <button className="view-case-study-btn">view case study</button>
          </Link>
        </div>
        <div className="dev-portfolio" data-aos="fade-up" data-aos-duration="800">
          <p className="card-number">3/3</p>
          <img src={devPortfolio} alt="" />
          <h1>developer portfolio</h1>
          <p className="card-par">
            A full desktop and mobile re-design of a Frontend Developer’s
            Portfolio website
          </p>
          <Link to="/devPortfolio">
            <button className="view-case-study-btn">view case study</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Portfolio;
