import Slide2 from "../Assets/wireframeSlide2.png"


const WireframeSlide2 = ({next, prev}) => {
  return (
    <div>
        <div className="wireframe-img-container">
        <img className="wireframe-slider-img" src={Slide2} alt="" />
        </div>
        <div onClick={next} className="wireframe-next-btn">next</div>
        <div onClick={prev} className="wireframe-prev-btn">prev</div>
    </div>
  )
}

export default WireframeSlide2