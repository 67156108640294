import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import AOS from "aos";
import "aos/dist/aos.css";

const MobileMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    AOS.init({
    });
  });

  return (
    <nav>
      <div className="mobile-menu-btn" onClick={toggleMenu}>
        <svg
          width="125"
          height="42"
          viewBox="0 0 131 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.402379"
            y="0.402379"
            width="130.195"
            height="47.1952"
            rx="23.5976"
            fill="#D9D9D9"
            stroke="black"
            strokeWidth="0.804758"
          />
          <path
            d="M34.156 32L37.04 17.188H38.832L38.412 19.4C39.644 17.748 41.296 16.88 43.144 16.88C45.272 16.88 46.504 18.028 46.84 19.792C48.156 17.888 50.032 16.88 52.048 16.88C54.568 16.88 55.912 18.504 55.912 20.94C55.912 21.416 55.856 21.92 55.744 22.452L53.896 32H52.076L53.924 22.48C54.036 22.032 54.064 21.584 54.064 21.192C54.064 19.512 53.252 18.42 51.488 18.42C49.024 18.42 47.204 20.772 46.672 22.984L44.936 32H43.116L44.964 22.48C45.076 22.032 45.104 21.584 45.104 21.192C45.104 19.512 44.292 18.42 42.528 18.42C40.008 18.42 38.132 20.912 37.684 23.152L35.976 32H34.156ZM69.9785 27.184C68.4385 30.796 65.7785 32.308 62.5585 32.308C59.1985 32.308 57.0705 30.236 57.0705 26.596C57.0705 21.556 60.3745 16.88 65.1625 16.88C68.7745 16.88 70.6225 19.008 70.6225 22.34C70.6225 22.984 70.5385 23.684 70.4265 24.384L70.3425 24.888H59.0585C58.9745 25.392 58.9465 25.896 58.9465 26.4C58.9465 29.256 60.3185 30.74 62.8105 30.74C65.1345 30.74 67.0665 29.564 68.1025 27.184H69.9785ZM65.0505 18.448C62.2785 18.448 60.2345 20.688 59.3665 23.488H68.7185C68.7465 23.152 68.7745 22.816 68.7745 22.48C68.7745 19.904 67.4865 18.448 65.0505 18.448ZM71.0252 32L73.9092 17.188H75.7012L75.2532 19.54C76.5692 17.804 78.3892 16.88 80.4052 16.88C83.2612 16.88 84.6332 18.504 84.6332 20.968C84.6332 21.416 84.5772 21.948 84.4652 22.452L82.5892 32H80.7972L82.6452 22.48C82.7292 22.06 82.7572 21.64 82.7572 21.276C82.7572 19.54 81.8892 18.42 79.8172 18.42C77.0732 18.42 75.0012 20.912 74.5532 23.152L72.8452 32H71.0252ZM96.1239 26.036L97.8599 17.188H99.6799L96.7959 32H94.9759L95.4239 29.704C94.0799 31.412 92.2879 32.308 90.4399 32.308C87.6959 32.308 86.2399 30.88 86.2399 28.332C86.2399 27.8 86.2959 27.268 86.4079 26.708L88.2559 17.188H90.0479L88.2279 26.736C88.1159 27.184 88.0599 27.576 88.0599 27.968C88.0599 29.676 88.9559 30.74 90.9439 30.74C93.6599 30.74 95.7039 28.276 96.1239 26.036Z"
            fill="#1C1712"
          />
        </svg>
      </div>
      {/* Render the fullscreen menu conditionally based on showMenu state */}
      {showMenu && (
        <div className={`fullscreen-menu ${showMenu ? "show" : ""}`}>
          <div className="mobile-nav-header">
            <svg
              width="120"
              height="12"
              viewBox="0 0 120 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mobile-nav-logo"
            >
              <path
                d="M8.464 11.12H10.736L6.64 0.240001H4.096L0 11.12H2.16L2.992 8.864H7.632L8.464 11.12ZM4.992 3.472L5.312 2.352L5.632 3.472L7.008 7.2H3.616L4.992 3.472Z"
                fill="#F8F3ED"
              />
              <path
                d="M11.125 11.12H18.325V9.376H13.285V0.240001H11.125V11.12Z"
                fill="#F8F3ED"
              />
              <path
                d="M18.9481 11.12H21.1081L21.0121 2.704L21.7961 5.152L24.1481 11.12H25.7001L28.0361 5.184L28.8361 2.672L28.7561 11.12H30.9001V0.240001H28.1161L25.3801 7.264L24.9641 8.624L24.5641 7.264L21.8121 0.240001H18.9481V11.12Z"
                fill="#F8F3ED"
              />
              <path
                d="M37.1219 11.36C40.4179 11.36 42.4659 9.2 42.4659 5.696C42.4659 2.192 40.4179 0 37.1219 0C33.8259 0 31.7939 2.192 31.7939 5.696C31.7939 9.2 33.8259 11.36 37.1219 11.36ZM37.1219 9.568C35.1539 9.568 34.0019 8.112 34.0019 5.696C34.0019 3.264 35.1539 1.792 37.1219 1.792C39.0899 1.792 40.2579 3.28 40.2579 5.696C40.2579 8.112 39.0899 9.568 37.1219 9.568Z"
                fill="#F8F3ED"
              />
              <path
                d="M45.184 2.976C45.184 2.224 45.84 1.728 46.928 1.728C48.096 1.728 48.848 2.288 49.104 3.504H51.392C51.04 1.056 49.184 0 46.992 0C44.416 0 42.992 1.44 42.992 3.168C42.992 7.504 49.488 5.424 49.488 8.192C49.488 9.072 48.8 9.632 47.456 9.632C46.144 9.632 45.312 8.992 45.04 7.84H42.784C43.2 10.208 44.96 11.36 47.44 11.36C49.904 11.36 51.696 10.112 51.696 7.92C51.696 3.472 45.184 5.584 45.184 2.976Z"
                fill="#F8F3ED"
              />
              <path
                d="M54.719 11.12H56.879V2.016H60.383V0.240001H51.215V2.016H54.719V11.12Z"
                fill="#F8F3ED"
              />
              <path
                d="M64.7159 2.976C64.7159 2.224 65.3719 1.728 66.4599 1.728C67.6279 1.728 68.3799 2.288 68.6359 3.504H70.9239C70.5719 1.056 68.7159 0 66.5239 0C63.9479 0 62.5239 1.44 62.5239 3.168C62.5239 7.504 69.0199 5.424 69.0199 8.192C69.0199 9.072 68.3319 9.632 66.9879 9.632C65.6759 9.632 64.8439 8.992 64.5719 7.84H62.3159C62.7319 10.208 64.4919 11.36 66.9719 11.36C69.4359 11.36 71.2279 10.112 71.2279 7.92C71.2279 3.472 64.7159 5.584 64.7159 2.976Z"
                fill="#F8F3ED"
              />
              <path
                d="M80.8781 6.976V0.240001H78.7341V6.976C78.7341 8.624 77.8541 9.616 76.3501 9.616C74.8621 9.616 73.9821 8.624 73.9821 6.976V0.240001H71.8221V6.976C71.8221 9.696 73.5501 11.36 76.3501 11.36C79.1501 11.36 80.8781 9.696 80.8781 6.976Z"
                fill="#F8F3ED"
              />
              <path
                d="M82.0537 11.12H84.2138L84.1178 3.152L84.8857 4.672L88.9178 11.12H91.1738V0.240001H89.0298L89.1097 8.192L88.3577 6.704L84.3258 0.240001H82.0537V11.12Z"
                fill="#F8F3ED"
              />
              <path
                d="M92.455 11.12H96.407C99.815 11.12 101.943 9.088 101.943 5.696C101.943 2.304 99.815 0.240001 96.407 0.240001H92.455V11.12ZM96.407 9.376H94.615V1.984H96.407C98.503 1.984 99.751 3.36 99.751 5.696C99.751 8.016 98.503 9.376 96.407 9.376Z"
                fill="#F8F3ED"
              />
              <path
                d="M109.8 11.12H112.072L107.976 0.240001H105.432L101.336 11.12H103.496L104.328 8.864H108.968L109.8 11.12ZM106.328 3.472L106.648 2.352L106.968 3.472L108.344 7.2H104.952L106.328 3.472Z"
                fill="#F8F3ED"
              />
              <path
                d="M113.559 11.12H115.703V7.248L119.511 0.240001H117.223L115.159 4.24L114.695 5.408L114.231 4.24L112.167 0.240001H109.751L113.559 7.248V11.12Z"
                fill="#F8F3ED"
              />
            </svg>
            <div className="close-button" onClick={toggleMenu}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.73579 0.21967C1.4429 -0.0732233 0.968023 -0.0732233 0.67513 0.21967C0.382237 0.512563 0.382237 0.987437 0.67513 1.28033L6.68322 7.28842L0.21967 13.752C-0.0732231 14.0449 -0.0732234 14.5197 0.21967 14.8126C0.512563 15.1055 0.987437 15.1055 1.28033 14.8126L7.74388 8.34908L14.2074 14.8126L15.2681 13.752L8.80454 7.28842L14.8126 1.28033L13.752 0.21967L7.74388 6.22776L1.73579 0.21967Z"
                  fill="#F1EEE8"
                />
              </svg>
            </div>
          </div>
          <ul>
            <div className="hidden">
            <li     data-aos="slide-up" data-aos-delay="100">
              <Link onClick={toggleMenu} to="/landing">
                home
              </Link>
            </li>
            </div>
            <div className="hidden">
            <li data-aos="slide-up" data-aos-delay="200">
              <Link onClick={toggleMenu} to="/about">
                about
              </Link>
            </li>
            </div>
            {/* <div className="hidden">
            <li data-aos="slide-up" data-aos-delay="300">
              <HashLink onClick={toggleMenu} to="/landing#skills">
                skills
              </HashLink>
            </li>
            </div> */}
            <div className="hidden">
            <li data-aos="slide-up" data-aos-delay="400">
              <HashLink onClick={toggleMenu} to="/landing#portfolio">
                projects
              </HashLink>
            </li>
            </div>
            <div className="hidden">
            <li data-aos="slide-up" data-aos-delay="500">
              <HashLink onClick={toggleMenu} to="/landing#contact">
                contact
              </HashLink>
            </li>
            </div>
          </ul>

        </div>
      )}
    </nav>
  );
};

export default MobileMenu;
