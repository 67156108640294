import { useRef, useEffect } from "react";

import video from "../Assets/influencedPrototype.mp4";
import influencedLogo2 from "../Assets/influencedLogo2.png";


function Prototype() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          video.play();
        } else {
          video.pause();
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(video);

    return () => {
      observer.unobserve(video);
    };
  }, []);

  return (
    <div className="prototype-container">
      <h1>Prototype</h1>
      <div className="prototype-text">
        <p>
          I created a high fidelity prototype to test some of the buttons and
          layouts as well as the overall flow of the screens
        </p>
        <img src={influencedLogo2} alt="logo" />
      </div>
      <video ref={videoRef} autoPlay loop muted playsInline controls={false}>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
}

export default Prototype;
